body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Spartan';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
}

code {
  font-family: 'Spartan';
}

html {
  font-size: 16px;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
@media screen and (min-width: 600px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 600px) / 680));
  }
}
@media screen and (min-width: 750px) {
  html {
    font-size: calc(16px + 6 * ((100vw - 750px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  html {
    font-size: 16px;
  }
}
